<template>
  <b-card v-if="Object.keys(commentItem).length > 0">
    <div class="d-flex align-items-start mb-1">
      <!-- avatar -->
      <!-- <b-avatar :src="commentItem.avatar" size="50" class="mr-1" />

      <div class="profile-user-info">
        <h6 class="mb-0">{{ commentItem.username }}</h6>
        <h6 class="mb-0">{{ commentItem.fullName }}</h6>
        <small class="text-muted">{{ commentItem.createdAt }}</small>
      </div> -->
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
            size="50"
            :src="commentItem.avatar"
            :text="avatarText(commentItem.fullName)"
            :variant="'primary'"
            :to="{
              name: 'amic-pages-profile',
              params: { id: commentItem.userId },
            }"
          />
        </template>
        <b-link
          :to="{
            name: 'amic-pages-profile',
            params: { id: commentItem.userId },
          }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ commentItem.fullName }}
        </b-link>
        <b-link
          :to="{
            name: 'amic-pages-profile',
            params: { id: commentItem.userId },
          }"
          class="font-weight-bolder text-dark"
          >@{{ commentItem.username }}</b-link
        >
        <br />
        <small class="text-muted">{{ commentItem.createdAt }}</small>
      </b-media>
    </div>
    <div style="display: flex">
      <div style="width: 100%">
        <b-card-text>
          <span
            v-html="
              setMentionedUsersLinks(
                commentItem.commentContent,
                commentItem.mentionedUsers
              )
            "
          />
        </b-card-text>
        <div v-if="commentItem.media" style="width: 100%">
          <b-img
            v-if="commentItem.media.mediaType == 'gif'"
            fluid
            rounded
            style="width: 100%"
            :src="commentItem.media.gifURL"
          />
          <div
            style="width: 100%"
            v-else-if="commentItem.media.mediaType == 'sticker'"
          >
            <lottie-vue-player
              v-if="
                stickerAnimationData != '' &&
                commentItem.media.stickerAnimated == true
              "
              :src="stickerAnimationData"
              :loop="true"
              :autoplay="true"
              :player-size="lottieOptions.playerSize"
              style="width: 100%; height: 100%; background: transparent"
            />
            <div
              v-else
              style="width: 100%; height: 100%; background: transparent"
            >
              <b-img-lazy
                style="width: 100%; height: 100%; background: transparent"
                v-if="commentItem.media.stickerAnimated == true"
                :src="
                  getStickerThumbnail(
                    getFullStickerUrl(commentItem.media.stickerUrl),
                    commentItem.media.stickerAnimated
                  )
                "
              />
              <b-img-lazy
                style="width: 100%; height: 100%; background: transparent"
                v-else="commentItem.media.stickerAnimated == true"
                :src="getFullStickerUrl(commentItem.media.stickerUrl)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div style="margin: 10%">
          <feather-icon
            icon="HeartIcon"
            class="mr-50 text-center"
            :class="'profile-likes'"
            size="30"
          />
        </div>
        <div>
          <span class="text-center text-muted align-middle">{{
            kFormatter(commentItem.likes)
          }}</span>
        </div>
      </div>
    </div>
    <!--/ post img -->
    <!-- post video -->
    <!-- <b-embed
        v-if="data.postVid"
        type="iframe"
        :src="data.postVid"
        allowfullscreen
        class="rounded mb-50"
      /> -->
    <!--/ post video -->
  </b-card>
  <b-card
    v-else
    class="position-relative text-center"
    style="min-height: 100px"
  >
    <b-spinner variant="primary" class="text-center" type="grow" />
  </b-card>
</template>

<script>
import {
  BAvatar, BCard, BCardText, BImg, BLink, BRow, BCol, BAvatarGroup, VBTooltip, BFormTextarea, BButton, BFormGroup, BEmbed, BSpinner, BMedia, BImgLazy
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getComment, getCommentLikesCount } from '@/amic-js/comment'
import { getSmallThumbnail, getMediumThumbnail } from '@/amic-js/stringutils'
import { avatarText } from '@core/utils/filter'



import { getAnimatedStickerData } from '@/amic-js/sticker'

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    BSpinner,
    BMedia,
    BImgLazy

  },
  methods: {
    kFormatter,
    getSmallThumbnail,
    getMediumThumbnail,
    getComment,
    getCommentLikesCount,
    setMentionedUsersLinks(text, mentionedUsers) {
      if (!text || mentionedUsers.length == 0) {
        return text
      }
     
      var split = text.split(" ");
      for (var i = 0; i < split.length; i++) {
        'user44{xw1SdoQSTG},user43{A1uYh3NfCk},user42{LyONF9BrrS}'
        if (split[i].indexOf('@') >= 0) {
          mentionedUsers.forEach(mention => {
            var mentionedUserId = mention
            const username = "@" + mentionedUserId.substring(0, mentionedUserId.indexOf("{"));
            // console.log("MENTIONED USER " + username +" Compared to " + "@"+split[i].substring(1))
            if (username == ("@"+split[i].substring(1))) {
              mentionedUserId = mentionedUserId.substring(mentionedUserId.indexOf("{") + 1, mentionedUserId.indexOf("}"));
              split[i] = `<a class="linkified" target="_blank" href="/profile/` + mentionedUserId + `">` + split[i] + `</a>`;
            }
          })
        }
      }
      console.log(split.join(" "));
      return split.join(" ")
    },
    getAnimatedStickerData,
    getStickerThumbnail(url, triggerLoadAnimationData) {
      if (triggerLoadAnimationData) {
        this.getAnimationData(this.getFullStickerUrl(url))
      }
      var thumbnailUrl
      if (url.endsWith(".tgs")) {
        thumbnailUrl = url.replace(".tgs", ".webp")
        thumbnailUrl = thumbnailUrl.replace("/tgs/", "/webp_thumbnail/")
      }
      else {
        thumbnailUrl = url.replace("/webp/", "/webp_thumbnail/")
      }
      return thumbnailUrl
    },
    async getAnimationData(url) {
      console.log("get animation data")
      const animationData = await getAnimatedStickerData(url)
      this.stickerAnimationData = animationData
      console.log("----> " + this.stickerAnimationData)
      return animationData
    },
    getFullStickerUrl(url) {
      if (url.startsWith("https://www.amic-team.tech")) {
        return url
      }
      else {
        return "https://www.amic-team.tech" + url
      }
    },
    fetchLikesCount(comment) {
      var commentId
      var isAReply
      if (comment) {
        commentId = this.commentData.commentId
        isAReply = this.commentData.isAReply
      }
      else {
        commentId = this.commentModelPrefetched.commentId
        isAReply = this.commentModelPrefetched.isAReply
      }
      getCommentLikesCount(commentId, isAReply).then(count => {
        if (comment) {
          const commentPublisher = comment.get(this.commentData.isAReply == true ? "commentReplyPublisher" : "commentPublisher")
          var userImageThumbnailURL = ""
          if (commentPublisher.get("userImage")) {
            userImageThumbnailURL = getMediumThumbnail(commentPublisher.get("userImage").url());
          }
          this.commentItem = {
            commentId: this.commentData.commentId,
            isAReply: this.commentData.isAReply,
            userId: commentPublisher.id,
            username: commentPublisher.get("username"),
            fullName: commentPublisher.get("firstName") + " " + commentPublisher.get("lastName"),
            avatar: userImageThumbnailURL,
            commentContent: comment.get(this.commentData.isAReply == true ? "commentReplyContent" : "commentContent"),
            mentionedUsers: comment.get("mentionedUsers") ? comment.get("mentionedUsers") : [],
            likes: count.get("numberOfLikes") ? count.get("numberOfLikes") : 0,
            media: comment.get("media") ? JSON.parse(comment.get("media")) : {},
            createdAt: comment.get("createdAt").toDateString()
          }
        }
        else {
          this.commentItem = this.commentModelPrefetched
          this.commentItem.likes = count.get("numberOfLikes") ? count.get("numberOfLikes") : 0
        }
      }, error => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error while fetching numver of likes for' + this.commentData.commentId + '  :' + error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
          {
            timeout: false,
          })
      })
    }

  },
  setup() {
    const toast = useToast();
    return { toast, avatarText }
  },
  data() {
    return {
      commentItem: {},
      lottieOptions: {
        minimizable: false,
        playerSize: "minimal",
        backgroundColor: '#6EE76D',
        backgroundStyle: 'color',
        theme: {
          controlsView: "standard",
          active: "light",
          light: {
            color: '#6EE76D',
            backgroundColor: '#6EE76D',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#6EE76D',
            opacity: '0.7',
          }
        }
      },
      stickerAnimationData: ''
    }
  },
  async mounted() {
    if (this.commentModelPrefetched) {
      this.fetchLikesCount(null)
    }
    else {
      getComment(this.commentData.commentId, this.commentData.isAReply).then(comment => {
        this.fetchLikesCount(comment)
      }, error => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching comment with id' + this.commentData.commentId + '  :' + error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
          {
            timeout: false,
          })
      })
    }

  },
  created() {
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    commentData: {
      type: Object,
      default: () => { },
    },
    commentModelPrefetched: {
      type: Object,
      default: () => { },
    }
  },
}
</script>
<style lang="scss" >
@import '../@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '../@core/scss/base/components/include'; // Components includes
.profile-likes {
  fill: $danger;
  stroke: $danger;
}
</style>