var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.commentItem).length > 0)?_c('b-card',[_c('div',{staticClass:"d-flex align-items-start mb-1"},[_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","src":_vm.commentItem.avatar,"text":_vm.avatarText(_vm.commentItem.fullName),"variant":'primary',"to":{
            name: 'amic-pages-profile',
            params: { id: _vm.commentItem.userId },
          }}})]},proxy:true}],null,false,733625768)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
          name: 'amic-pages-profile',
          params: { id: _vm.commentItem.userId },
        }}},[_vm._v(" "+_vm._s(_vm.commentItem.fullName)+" ")]),_c('b-link',{staticClass:"font-weight-bolder text-dark",attrs:{"to":{
          name: 'amic-pages-profile',
          params: { id: _vm.commentItem.userId },
        }}},[_vm._v("@"+_vm._s(_vm.commentItem.username))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.commentItem.createdAt))])],1)],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('b-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.setMentionedUsersLinks(
              _vm.commentItem.commentContent,
              _vm.commentItem.mentionedUsers
            )
          )}})]),(_vm.commentItem.media)?_c('div',{staticStyle:{"width":"100%"}},[(_vm.commentItem.media.mediaType == 'gif')?_c('b-img',{staticStyle:{"width":"100%"},attrs:{"fluid":"","rounded":"","src":_vm.commentItem.media.gifURL}}):(_vm.commentItem.media.mediaType == 'sticker')?_c('div',{staticStyle:{"width":"100%"}},[(
              _vm.stickerAnimationData != '' &&
              _vm.commentItem.media.stickerAnimated == true
            )?_c('lottie-vue-player',{staticStyle:{"width":"100%","height":"100%","background":"transparent"},attrs:{"src":_vm.stickerAnimationData,"loop":true,"autoplay":true,"player-size":_vm.lottieOptions.playerSize}}):_c('div',{staticStyle:{"width":"100%","height":"100%","background":"transparent"}},[(_vm.commentItem.media.stickerAnimated == true)?_c('b-img-lazy',{staticStyle:{"width":"100%","height":"100%","background":"transparent"},attrs:{"src":_vm.getStickerThumbnail(
                  _vm.getFullStickerUrl(_vm.commentItem.media.stickerUrl),
                  _vm.commentItem.media.stickerAnimated
                )}}):_c('b-img-lazy',{staticStyle:{"width":"100%","height":"100%","background":"transparent"},attrs:{"src":_vm.getFullStickerUrl(_vm.commentItem.media.stickerUrl)}})],1)],1):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"text-center"},[_c('div',{staticStyle:{"margin":"10%"}},[_c('feather-icon',{staticClass:"mr-50 text-center",class:'profile-likes',attrs:{"icon":"HeartIcon","size":"30"}})],1),_c('div',[_c('span',{staticClass:"text-center text-muted align-middle"},[_vm._v(_vm._s(_vm.kFormatter(_vm.commentItem.likes)))])])])])]):_c('b-card',{staticClass:"position-relative text-center",staticStyle:{"min-height":"100px"}},[_c('b-spinner',{staticClass:"text-center",attrs:{"variant":"primary","type":"grow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }