import * as Parse from './parse'

export async function getComment(id, isAReply) {
    const Comment = Parse.Object.extend(isAReply == true ? "CommentReply" : "Comment")
    const query = new Parse.Query(Comment)
    query.equalTo("objectId", id)
    query.include(isAReply == true ? "commentReplyPublisher" : "commentPublisher")
    return await query.first({useMasterKey: true})
}

export async function getCommentLikesCount(id, isAReply){
    const LikeCount = Parse.Object.extend("LikeCount")
    const query = new Parse.Query(LikeCount)
    const Comment = Parse.Object.extend(isAReply == true ? "CommentReply" : "Comment")
    const pointer = Comment.createWithoutData(id)
    query.equalTo(isAReply == true ? "commentReply" : "comment", pointer)
    return await query.first({useMasterKey: true})
}

export async function getParentComment(id){
    const Comment = Parse.Object.extend("Comment")
    const query = new Parse.Query(Comment)
    query.equalTo("objectId", id)
    query.include(["comment.commentPublisher"]);
    return await query.first({useMasterKey: true})
}

export async function getCommentReplies(parenctCommentId, skip, limit){
    const Comment = Parse.Object.extend("Comment")
    const CommentReply = Parse.Object.extend("CommentReply")
    const query = new Parse.Query(CommentReply)
    query.equalTo("parentComment", Comment.createWithoutData(parenctCommentId))
    query.skip(skip)
    query.limit(limit)
    query.descending("createdAt")
    query.include(["comment.commentReplyPublisher"]);
    return await query.find({useMasterKey: true})
}