import axios from 'axios'
import { ungzip } from 'pako';


export async function getAnimatedStickerData(url) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            headers: {
                'Content-Encoding': 'utf-8'
            },
            responseType: 'arraybuffer', 'decompress': true
        })
            .then(function (response) {
                // handle success
                var decompressedBuffer = ungzip(response.data)
                var arr = new Uint8Array(decompressedBuffer);
                const str = String.fromCharCode.apply(String, arr);
                resolve(str)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}