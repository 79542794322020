
export function getMediumThumbnail(imageLink){
    let link = imageLink;
    let extension = link.substring(link.lastIndexOf("."));
    link = link.substring(0, link.length - extension.length);
    return link + "_medium" + extension;
}

export function getSmallThumbnail(imageLink){
    let link = imageLink;
    let extension = link.substring(link.lastIndexOf("."));
    link = link.substring(0, link.length - extension.length);
    return link + "_small" + extension;
}