<template>
  <div>
    <!--  ban user modal -->
    <b-modal
      id="modal-ban"
      cancel-variant="outline-secondary"
      ok-title="Ban and archive report"
      cancel-title="Cancel"
      centered
      @ok="deleteCommentBanArchiveReport(modalReport)"
      :title="
        'Select ban reason for ' +
        modalReport.username +
        '(' +
        modalReport.id +
        ')'
      "
    >
      <b-form>
        <b-form-group label="">
          <b-form-checkbox-group
            v-model="selectedBanOptions"
            :options="banOptions"
            name="flavour-2a"
            stacked
            class="demo-vertical-spacing"
          />
          <b-form-input
            v-model="otherReasonInput"
            id="banReasonInput"
            placeholder="Other reason "
          />
          <br />
        </b-form-group>
        <b-form-checkbox
          v-model="deleteCommentCheckbox"
          :value="true"
          :checked="true"
        >
          <strong class="text-danger"> Delete comment </strong>
        </b-form-checkbox>
      </b-form>
    </b-modal>
    <!--  ban user modal -->

    <!-- delete comment modal  -->
    <b-modal
      id="modal-delete-comment"
      cancel-variant="outline-secondary"
      ok-title="Delete and archive report"
      cancel-title="Cancel"
      centered
      @ok="deleteCommentArchiveReport(modalReport)"
      :title="'Delete comment confirmation'"
    >
      <strong> Are you sure you want to delete this comment? </strong>
    </b-modal>
    <!-- delete comment modal  -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!--b-table striped-->
        <b-table
          class="position-relative"
          style="min-height: 300px"
          :items="reportedComments"
          responsive
          :fields="fields"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(details)="row" style="max-width: 10px">
            <feather-icon
              :icon="row.detailsShowing ? 'Minimize2Icon' : 'Maximize2Icon'"
              @click="row.toggleDetails"
              href=""
              class="text-info cursor-pointer"
              badge-classes="badge-info"
              size="21"
            />
          </template>

          <!-- expandable item for each row -->
          <template #row-details="row">
            <b-card class="border mb-0">
              <b-row class="mb-2">
                <b-col md="6">
                  <div
                    class="m-2 text-center"
                    v-if="row.item.commentModel.isAReply == true"
                  >
                    <strong
                      v-if="row.item.parentCommentLoaded == true"
                      class="text-info"
                    >
                      Parent comment:
                    </strong>
                    <div
                      v-if="row.item.parentCommentLoaded == true"
                      style="
                        position: relative;
                        height: 500px;
                        overflow-y: scroll;
                      "
                    >
                      <comment-component
                        class="border mb-2"
                        :comment-model-prefetched="row.item.parentComment"
                      />
                    </div>
                    <b-button
                      v-else
                      variant="info"
                      class="mr-1"
                      @click="loadParentComment(row.item)"
                    >
                      Show parent comment
                    </b-button>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="m-2 text-center">
                    <strong
                      v-if="row.item.replies.length > 0"
                      class="text-info"
                    >
                      More replies related to this comment:
                    </strong>
                    <div
                      style="
                        position: relative;
                        height: 500px;
                        overflow-y: scroll;
                      "
                      v-if="row.item.replies.length > 0"
                    >
                      <div v-for="reply in row.item.replies" :key="reply">
                        <comment-component
                          class="border mb-2"
                          :comment-model-prefetched="reply"
                        />
                      </div>
                    </div>
                    <div v-if="row.item.allRepliesLoaded == false">
                      <b-button
                        v-if="row.item.repliesLoading == true"
                        variant="outline-info"
                        disabled
                        class="mr-1"
                        @click=""
                      >
                        <b-spinner small />
                        Loading replies...
                      </b-button>
                      <b-button
                        v-else
                        variant="outline-info"
                        class="mr-1"
                        @click="loadReplies(row.item)"
                      >
                        Show more replies
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button variant="outline-info" disabled class="mr-1">
                        No more replies
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="4" class="mb-1">
                  <strong>Number of reports: </strong
                  >{{ row.item.numberOfReports }}
                </b-col>
                <!-- Global reports information -->
                <b-col md="2" class="mb-1">
                  <strong>Reported For: </strong>
                  <div v-for="reason in row.item.reasonsGlobal" :key="reason">
                    <div>
                      <b-row class="mb-2">
                        {{ getReportReason(reason.reason) }}
                        <strong
                          :class="getNumberOfReportsClass(reason.occurrence)"
                        >
                          &nbsp; x{{ reason.occurrence }}
                        </strong>
                      </b-row>
                    </div>
                  </div>
                </b-col>
                <!-- Each report individually -->
                <b-col
                  md="4"
                  class="mb-1"
                  style="position: relative; height: 300px; overflow-y: scroll"
                >
                  <strong>Reports details: </strong>
                  <div v-for="reason in row.item.reasons" :key="reason">
                    <div>
                      <b-row class="border mb-2">
                        <b-col>
                          {{ getReportReason(reason.reason) }}
                        </b-col>
                        <b-col>
                          {{ reason.information }}
                        </b-col>
                        <b-col>
                          <b-link
                            :to="{
                              name: 'amic-pages-profile',
                              params: { id: reason.reportedBy },
                            }"
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ reason.reportedBy }}
                          </b-link>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide report
              </b-button>
            </b-card>
          </template>

          <!--template #cell(user)="row">
        <!-- <b-avatar :src="data.value" /> >
        <b-media vertical-align="center">
          <b-avatar
            size="32"
            :src="row.item.avatar"
            :text="avatarText(row.item.fullName)"
          />
          <small class="text-muted">@{{ row.item.username }}</small>
        </b-media>
      </template-->

          <template #cell(commentPublisher)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.commentModel.avatar"
                  :text="avatarText(data.item.commentModel.fullName)"
                  :variant="status[1][0]"
                  :to="{
                    name: 'amic-pages-profile',
                    params: { id: data.item.commentModel.userId },
                  }"
                />
              </template>
              <b-link
                :to="{
                  name: 'amic-pages-profile',
                  params: { id: data.item.commentModel.userId },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.commentModel.fullName }}
              </b-link>
              <b-link
                :to="{
                  name: 'amic-pages-profile',
                  params: { id: data.item.commentModel.userId },
                }"
                class="font-weight-bolder text-dark"
                >@{{ data.item.commentModel.username }}</b-link
              >
            </b-media>
            <br>
              Account status:
              <b-badge :variant="data.item.commentPublisherObject.get('isBanned') ? 'light-danger' : 'light-success'">
                {{ data.item.commentPublisherObject.get("isBanned") == true ? 'Banned' : 'Active' }}
              </b-badge>
          </template>

          <!-- comment column -->
          <template #cell(comment)="data">
            <comment-component
              :comment-model-prefetched="data.item.commentModel"
            />
          </template>

          <!-- isAReply column -->
          <template #cell(commentType)="data">
            <b-badge variant="light-info">
              {{ data.item.isAReply == true ? 'Reply' : 'Comment'}}
            </b-badge>
          </template>

          <!-- action dropdown menu  -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'amic-pages-profile',
                  params: { id: data.item.commentModel.userId },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show profile</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.modal-delete-comment
                @click="setCurrentBanModalFor(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete comment</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-ban
                @click="setCurrentBanModalFor(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Ban permanently</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Ban temporarly</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="
                  deleteReportDialog(data.item.reportId, data.item.username)
                "
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete report</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <div class="m-2 text-center" v-if="noMoreData == false">
        <b-button
          variant="primary"
          disabled
          class="mr-1"
          v-if="isLoading == true"
          @click="cancelLoadMore"
        >
          <b-spinner small />
          Loading...
        </b-button>
        <b-button variant="primary" class="mr-1" @click="loadMore" v-else>
          Load more
        </b-button>
      </div>
      <div class="m-2 text-center" v-else>
        <b-button variant="primary" disabled class="mr-1" @click="loadMore">
          No more data
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BMedia, BLink,
  BDropdown, BDropdownItem, BSpinner, BImg,
  BFormGroup, BFormCheckboxGroup, BCardText, BFormInput, BModal, BForm
} from 'bootstrap-vue'
import CommentComponent from '@/views/amic-views/components/comment/CommentComponent.vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { getReportedComments, commenteDeleteBanUser, commentDelete } from '@/amic-js/reports'
import { getParentComment, getCommentReplies } from '@/amic-js/comment'
import { getReportReason, getReasonsWithOccurences } from '@/amic-js/reportutils'
import { getSmallThumbnail, getMediumThumbnail } from '@/amic-js/stringutils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'




export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    BFormGroup,
    BFormCheckboxGroup,
    BCardText,
    BFormInput,
    BModal,
    BForm,
    CommentComponent,
  },
  data() {
    return {
      modalReport: {},
      selectedBanOptions: [],
      deleteCommentCheckbox: true,
      otherReasonInput: '',
      banOptions: [
        { text: getReportReason(0), value: '0' },
        { text: getReportReason(1), value: '1' },
        { text: getReportReason(2), value: '2' },
        { text: getReportReason(3), value: '3' },
        { text: getReportReason(4), value: '4' }
      ],
      limitPerPage: 10,
      skip: 0,
      noMoreData: false,
      isLoading: false,
      fields: [{ key: 'details', class: 'limied-width-column' }, { key: 'reportId', label: "id", sortable: true }, { key: 'commentPublisher', sortable: true }
        , 'comment', { key: 'commentModel.createdAt', label: "Comment date" }, 'commentType', 'actions'],
      reportedComments: [
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }]
    }
  },
  methods: {
    getReportedComments,
    commenteDeleteBanUser,
    commentDelete,
    getReasonsWithOccurences,
    getSmallThumbnail,
    getMediumThumbnail,
    getReportReason,
    getParentComment,
    getCommentReplies,
    loadReplies(item) {
      const index = this.reportedComments.indexOf(item)
      if (index > -1) {
        this.reportedComments[index].repliesLoading = true
        const commentId = this.reportedComments[index].isAReply == true ? this.reportedComments[index].parentCommentId
          : this.reportedComments[index].commentModel.commentId
        getCommentReplies(commentId, this.reportedComments[index].replies.length, 10).then(replies => {
          if (replies.length == 0) {
            this.reportedComments[index].allRepliesLoaded = true
          }
          else {
            replies.forEach(reply => {
              const commentPublisher = reply.get("commentReplyPublisher")
              var userImageURL = ""
              var userImageThumbnailURL = ""
              if (commentPublisher.get("userImage")) {
                userImageURL = commentPublisher.get("userImage").url();
                userImageThumbnailURL = getMediumThumbnail(userImageURL);
              }
              const commentModel = {
                commentId: reply.id,
                isAReply: true,
                userId: commentPublisher.id,
                username: commentPublisher.get("username"),
                fullName: commentPublisher.get("firstName") + " " + commentPublisher.get("lastName"),
                avatar: userImageThumbnailURL,
                commentContent: reply.get("commentReplyContent"),
                media: reply.get("media") ? JSON.parse(reply.get("media")) : {},
                mentionedUsers: reply.get("mentionedUsers") ? reply.get("mentionedUsers") : [],
                createdAt: reply.get("createdAt").toDateString() + " " + reply.get("createdAt").toISOString().slice(11, (reply.get("createdAt").toISOString().length) - 5)
              }
              this.reportedComments[index].replies.push(commentModel)
            });
          }
          this.reportedComments[index].repliesLoading = false
        }, error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error Loading replies comment for this replay ' + item.commentModel.commentId + ' :' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        })
      }
      else {
        this.reportedComments[index].repliesLoading = false
      }
    },
    loadParentComment(item) {
      const index = this.reportedComments.indexOf(item)
      if (index > -1) {
        this.getParentComment(item.parentCommentId).then((parentComment) => {
          if (parentComment) {
            const commentPublisher = parentComment.get("commentPublisher")
            var userImageURL = ""
            var userImageThumbnailURL = ""
            if (commentPublisher.get("userImage")) {
              userImageURL = commentPublisher.get("userImage").url();
              userImageThumbnailURL = getMediumThumbnail(userImageURL);
            }
            const commentModel = {
              commentId: parentComment.id,
              isAReply: false,
              userId: commentPublisher.id,
              username: commentPublisher.get("username"),
              fullName: commentPublisher.get("firstName") + " " + commentPublisher.get("lastName"),
              avatar: userImageThumbnailURL,
              commentContent: parentComment.get("commentContent"),
              mentionedUsers: parentComment.get("mentionedUsers") ? parentComment.get("mentionedUsers") : [],
              media: parentComment.get("media") ? JSON.parse(parentComment.get("media")) : {},
              createdAt: parentComment.get("createdAt").toDateString() + " " + parentComment.get("createdAt").toISOString().slice(11, (parentComment.get("createdAt").toISOString().length) - 5)
            }
            this.reportedComments[index].parentComment = commentModel;
            this.reportedComments[index].parentCommentLoaded = true
          }
          else {
            return
          }
        }, (error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error Loading parent comment for this reply' + item.commentModel.commentId + ' :' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        })
      }
      else {
        return
      }
    },
    loadMore() {
      this.isLoading = true;
      getReportedComments(this.skip, this.limitPerPage).then((reports) => {
        this.isLoading = false;
        this.skip = this.skip + reports.length;
        if (reports.length == 0) {
          this.noMoreData = true;
        }
        else {
          reports.forEach(report => {
            var isAReply = false
            if (report.get("comment")) {
              isAReply = false
            }
            else if (report.get("commentReply")) {
              isAReply = true
            }
            else {
              return
            }
            const commentPublisher = isAReply == true ? report.get("commentReply").get("commentReplyPublisher") : report.get("comment").get("commentPublisher")
            const commentId = isAReply == true ? report.get("commentReply").id : report.get("comment").id
            const comment = isAReply == true ? report.get("commentReply") : report.get("comment")

            var userImageURL = ""
            var userImageThumbnailURL = ""
            if (commentPublisher.get("userImage")) {
              userImageURL = commentPublisher.get("userImage").url();
              userImageThumbnailURL = getMediumThumbnail(userImageURL);
            }
            const commentModel = {
              commentId: commentId,
              isAReply: isAReply,
              userId: commentPublisher.id,
              username: commentPublisher.get("username"),
              fullName: commentPublisher.get("firstName") + " " + commentPublisher.get("lastName"),
              avatar: userImageThumbnailURL,
              commentContent: comment.get(isAReply == true ? "commentReplyContent" : "commentContent"),
              mentionedUsers: comment.get("mentionedUsers") ? comment.get("mentionedUsers") : [],
              media: comment.get("media") ? JSON.parse(comment.get("media")) : {},
              createdAt: comment.get("createdAt").toDateString() + " " + comment.get("createdAt").toISOString().slice(11, (comment.get("createdAt").toISOString().length) - 5),
              commentObject: comment
            }
            const reasons = report.get("reasons") ? report.get("reasons") : []
            const reasonsGlobal = report.get("reasons") ? getReasonsWithOccurences(report.get("reasons"), "reason") : []
            const numberOfReports = report.get("reasons") ? report.get("reasons").length : 0
            const reportModel = {
              reportId: report.id,
              commentPublisher: commentPublisher,
              commentModel: commentModel,
              isAReply: isAReply,
              numberOfReports: numberOfReports,
              reasonsGlobal: reasonsGlobal,
              reasons: reasons,
              parentCommentLoaded: false,
              parentCommentId: isAReply == true ? comment.get("parentComment").id : '',
              parentComment: {},
              replies: [],
              repliesLoading: false,
              allRepliesLoaded: false,
              reportObject: report,
              commentPublisherObject: commentPublisher
            }
            this.reportedComments.push(reportModel)
          });
        }
      },
        (error) => {
          this.isLoading = false;
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list ' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        });

    },
    cancelLoadMore() {
      this.isLoading = false;
    },
    setCurrentBanModalFor(item) {
      this.modalReport = item
    },
    deleteCommentArchiveReport(item) {
      const result = commentDelete(item.reportObject, item.commentModel.commentObject)
      if (result instanceof Promise) {
        result.then((user) => {
          const index = this.reportedComments.indexOf(item);
          if (index > -1) {
            this.reportedComments.splice(index, 1);
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: item.parseUser.get("username") + ' has been banned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
            {
              timeout: false,
            })

        }, (error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error the report has beedn archived but failed to ban the user ' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        })
      }
      else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list ' + banResult.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
          {
            timeout: false,
          })
      }
    },
    deleteCommentBanArchiveReport(item) {
      /*  console.log("Item id "+ item.reportId)
       console.log("selected Ban reason "+this.selectedBanOptions)
       console.log("other reason input: "+ this.otherReasonInput )
       console.log("delete comment ?" + this.deleteCommentCheckbox) */

      const result = commenteDeleteBanUser(item.reportObject, item.commentModel.commentObject, item.commentPublisherObject)
      if (result instanceof Promise) {
        result.then((user) => {
          const index = this.reportedComments.indexOf(item);
          if (index > -1) {
            this.reportedComments.splice(index, 1);
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: item.parseUser.get("username") + ' has been banned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
            {
              timeout: false,
            })

        }, (error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error the report has beedn archived but failed to ban the user ' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        })
      }
      else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list ' + banResult.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
          {
            timeout: false,
          })
      }

    },
    showBanDialog(id, username) {
      this.$swal({
        title: 'Are you sure?',
        text: username + " with id " + id + " will be banned",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Permanent Ban',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: username + ' with id: ' + id + ' has been banned',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    deleteReportDialog(id, username) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you usre you want to delete the report (' + id + ") for " + username,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete report',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: id + ' report for user: ' + username + ' has been deleted',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    getNumberOfReportsClass(n) {
      if (n < 10) {
        return "text-info"
      }
      else if (n > 10 && n < 20) {
        return "text-warning"
      }
      else if (n > 20) {
        return "text-danger"
      }
    }
  },
  setup() {
    const toast = useToast();
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    return { toast, sortBy, avatarText, isSortDirDesc }
  },
  mounted() {
    this.loadMore()
  }
}
</script>
<style  >
.limied-width-column {
  max-width: 50px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>