import * as Parse from './parse'

export async function getReportedUsers(skip, limit) {
    const ReportUser = Parse.Object.extend("ReportUser");
    const query = new Parse.Query(ReportUser);
    query.skip(skip)
    query.limit(limit)
    query.equalTo("status", "pending")
    //query.include("user");
    query.include(["user"]);
    query.include(["user.userInformation.userInformationBirthday"]);
    query.include(["user.userInformation.userInformationSex"]);
    query.include(["user.userInformation.userInformationCountry"]);
    query.include(["user.userInformation.userInformationCity"]);
    query.include(["user.userInformation.userInformationEmail"]);
    query.include(["user.userInformation.userInformationPhone"]);

    query.descending("createdAt")
    return await query.find({ useMasterKey: true });
}

export async function getReportedComments(skip, limit) {
    const ReportComment = Parse.Object.extend("ReportComment");
    const query = new Parse.Query(ReportComment);
    query.skip(skip)
    query.limit(limit)
    query.equalTo("status", "pending")
    query.include("comment");
    query.include("commentReply");
    query.include(["comment.commentPublisher"]);
    query.include(["commentReply.commentReplyPublisher"]);
    query.descending("createdAt")
    return await query.find({ useMasterKey: true });
}

export async function banReportedUser(report, user) {
    report.set("status", "banned")
    await report.save(null, { useMasterKey: true }).then(async (archivedReport) => {
        user.set("isBanned", true)
        return await user.save(null, { useMasterKey: true })
    },
        (archiveError) => {
            return archiveError
        })
}

export async function commenteDeleteBanUser(report, comment, user) {
    report.set("status", "banned")
    await report.save(null, { useMasterKey: true }).then(async (archivedReport) => {
        await comment.destroy({ useMasterKey: true }).then(async deletedComment => {
            user.set("isBanned", true)
            return await user.save(null, { useMasterKey: true })
        }, deleteCommentError => {
            return deleteCommentError
        })

    },
        (archiveError) => {
            return archiveError
        })
}

export async function commentDelete(report, comment) {
    report.set("status", "comment deleted")
    await report.save(null, { useMasterKey: true }).then(async (archivedReport) => {
        return await comment.destroy({ useMasterKey: true })
    },
        (archiveError) => {
            return archiveError
        })
}


export async function deleteReport(report) {
    return await report.destroy({ useMasterKey: true })
}

export async function deleteUserReportById(id) {
    const ReportUser = Parse.Object.extend("ReportUser");
    const query = new Parse.Query(ReportUser);
    query.equalTo("objectId", id)
    await query.first({ useMasterKey: true }).then(async (report) => {
        return await report.destroy({ useMasterKey: true })
    }, (error) => {
        return error
    })
}