
const reportUserReasons = {
  PRETENDING_TO_BE_SOMEONE: 0,
  UNDESIRABLE_HARMFUL: 1,
  INAPPROPRIATE_CONTENT: 2,
  HARASSMENT_INTIMIDATION: 3,
  OTHER: 4
}
export function getReportReason(id) {
  switch (id) {
    case reportUserReasons.PRETENDING_TO_BE_SOMEONE:
      return 'Prentending to be someone';
    case reportUserReasons.UNDESIRABLE_HARMFUL:
      return 'Undesirable or harmful';
    case reportUserReasons.INAPPROPRIATE_CONTENT:
      return 'Inappropriate content';
    case reportUserReasons.HARASSMENT_INTIMIDATION:
      return 'Harassement or intimidation';
    case reportUserReasons.OTHER:
      return 'Other';
  }
}

export function getReasonsWithOccurences(arr, key) {
  /*  let a = [],
     b = [],
     arr = [...reasons], // clone array so we don't change the original when using .sort()
     prev;
 
   arr.sort();
   for (let element of arr) {
     if (element !== prev) {
       a.push(element);
       b.push(1);
     }
     else ++b[b.length - 1];
     prev = element;
   }
   
   const finalItems = []
   for(let i=0; i< a.length; i++){
     const item = {
       item: a[i],
       occurrence: b[i]
     }
     finalItems.push(item)
   }
 
   return finalItems; */
  let arr2 = [];

  arr.forEach((x) => {

    // Checking if there is any object in arr2
    // which contains the key value
    if (arr2.some((val) => { return val[key] == x[key] })) {

      // If yes! then increase the occurrence by 1
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++
        }
      })

    } else {
      // If not! Then create a new object initialize 
      // it with the present iteration key's value and 
      // set the occurrence to 1
      let a = {}
      a[key] = x[key]
      a["occurrence"] = 1
      arr2.push(a);
    }
  })

  return arr2
}